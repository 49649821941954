.formulario {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-self: center;
    align-self: center;
    margin-top: 5%;

}

.formulario form {
    width: 100%;
    margin: auto;
}


.formulario input,
.formulario textarea {
    padding: 5px;
    margin-bottom: 15px;
    min-width: 300px;
    width: 60%;
}

.formulario textarea {
    min-height: 100px;
}

.activeButton {
    color: #000;
    background-color: transparent;
    border-radius: 15px;
    width: 130px;
    padding: 15px 0 15px 0;
    font-size: 20px;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.activeButton:hover {
    background-color: #b8860b;
}


.formulario input::placeholder,
.formulario textarea::placeholder {
    font-size: 15px;
    font-family: 'Times New Roman', Times, serif;
}

.inactiveButton {
    color: #484848;
    background-color: #a7a7a7;
    border: solid 1px #484848;
    border-radius: 15px;
    width: 130px;
    padding: 15px 0 15px 0;
    font-size: 20px;
}

.recaptchaContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

@media screen and (max-width: 820px) {

    .formulario {
        margin-top: 0%;
    }

}

@media screen and (max-width: 380px) {

    .formulario input,
    .formulario textarea {
        min-width: 300px;
        width: 60%;
    }

}