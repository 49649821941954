.contactContainer {
    width: 100%;
    height: 100vh;
    color: #000;
}

.titleContact {
    width: 100%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    padding-bottom: 5px;
    z-index: 1;
    position: absolute;

}

.titleContact hr.solid {
    margin-top: 4%;
    border-top: 1px solid #000;
    width: 80%;
    z-index: 1;
    position: absolute;
}

.contactInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-content: center;
    padding: 10%;
    margin-top: -5%;
}

.contactLinks,
.contactLinks1,
.contactLinks4 {
    margin-left: 11.5%;
}

.mascara {
    margin: auto;
    margin-top: 2.5%;
    display: grid;
    justify-content: center;
    align-self: center;
    grid-template-columns: repeat(2, 50%);
    width: 95%;
    height: 95%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 20px;

}

.contactInfo a,
path {
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 20px;
    padding: 2% 5% 5%;
    text-align: justify;
    color: #000;
    line-height: 50px;
}

.contactInfo a:hover {
    color: #b8860b;
    fill: #b8860b;
    text-decoration: underline;
}

@media screen and (max-width: 1076px) {

    .contactLinks1 a,
    .contactLinks4 a {
        font-size: 16.2px;
        line-height: 20px;
    }


}

@media screen and (max-width: 875px) {
    .mascara {
        grid-template-columns: repeat(1, auto);
    }

    .titleContact hr.solid {
        border: none;
    }

    .contactLinks1 a,
    .contactLinks4 a {
        font-size: 20px;
        line-height: 50px;
    }

    .contactInfo {
        margin: auto;
        padding-left: 13%;
        padding-top: 8%;
        padding-bottom: 5%;
    }

    .titleContact {
        margin-top: 2%;
    }

    .contactContainer {
        height: auto;
    }

    .contactForm {
        padding-bottom: 25px;
    }

}

@media screen and (max-width: 660px) {

    .contactInfo {
        padding-top: 12%;
    }

}

@media screen and (max-width: 553px) {

    .contactLinks,
    .contactLinks1,
    .contactLinks4 {
        margin-left: 2%;
    }

}

@media screen and (max-width: 499px) {

    .contactInfo {
        padding-left: 10%;
    }

    .contactInfo a:nth-child(2) {
        font-size: 17px;
        line-height: 40px;
    }

    .contactLinks,
    .contactLinks1,
    .contactLinks4 {
        font-size: 13px;
    }

    .titleContact {
        font-size: 23px;
    }

}

@media screen and (max-width: 394px) {

    .contactInfo {
        width: 400px;
        margin-top: 6%;
        margin-left: 30px;
    }

    .contactLinks,
    .contactLinks1,
    .contactLinks4 {
        margin-left: 0%;
    }

    .contactInfo a,
    path {
        font-size: 20px;
        text-align: justify;
        line-height: 30px;

    }

    .contactInfo a:nth-child(2) {
        font-size: 15px;
    }

    .mascara {
        width: 90%;
        margin-left: 17.5px;
        margin-top: 4vh;
    }

    .titleContact hr.solid {
        margin-top: 12%;
    }

    .titleContact {
        font-size: 23px;
    }

}

/* .frame {
    border: #b8860b solid 2px;
    border-radius: 20px;

}

.frame iframe {
    border-radius: 20px;
} */