* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif', serif;
}

.ppalDiv {
  height: 100%;
  background: url('./img/fondo1.webp') no-repeat 55%;
  background-size: cover;
}

hr.solid {
  margin: auto;
  border-top: 1px solid #000;
  width: 90%;
}