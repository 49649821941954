.footerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 50px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);


    /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

.footerDiv p {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 17px;
}