.NavbarItems {
    /* background: linear-gradient(90deg, rgba(255, 239, 216, 1) 0%, rgba(240, 215, 186, 1) 35%, rgba(210, 189, 162, 1) 100%); */
    background-color: transparent;
    /* border-bottom: #464545 solid 1px; */
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    /* width: 70px; */
    width: 350px;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-bars {
    color: #000;
    margin-right: 0;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 20px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-menu.active {
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 20px;
    list-style: none;
    text-align: center;
    width: 70%;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    background-image: linear-gradient(to right,
            #b98400,
            #b8860b 50%,
            #000 50%);
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:before {
    content: '';
    background: #b8860b;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.4s ease-in-out;
}

.nav-links:hover {
    background-position: 0;
    color: #b8860b;
}

.nav-links:hover::before {
    width: 100%;
}

/* .nav-links:hover {
    border-bottom: 2px solid #000;
    /* transition: all 0.2s ease-out; */

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {

    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 361px;
        position: absolute;
        top: 119px;
        left: -80%;
        opacity: 1;
        transition: all 0.5s ease;
        background: linear-gradient(180deg, rgba(236, 218, 197, 1) 0%, rgba(240, 215, 186, 1) 35%, rgba(255, 255, 255, 1) 100%);
    }

    .nav-menu.active {
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, rgba(236, 218, 197, 1) 0%, rgba(240, 215, 186, 1) 35%, rgba(255, 255, 255, 1) 100%);
        /* background: #ecdac5; */
        left: 0;
        opacity: 1;
        width: 100%;
        transition: all 0.5s ease;
        z-index: 2;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #b8860b;
        border-radius: 0;
        transition: all 0.3s ease-out;
    }

    .nav-links:hover::before {
        width: 50%;
        margin-left: 25%;
    }

    .navbar-logo {
        position: absolute;
        /* top: 14px; */
        top: 1px;
        left: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 18px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

}

@media screen and (max-width: 415px) {
    .navbar-logo {
        top: 2px;
        width: 320px;
        margin-left: 25px;
    }

}

@media screen and (max-width: 375px) {
    .navbar-logo {
        top: 10px;
        width: 290px;
        margin-left: 16px;
    }

}