.serviciosComp {
    background-color: transparent;
    width: 100%;
    height: 100vh;

}

.serviciosTitulo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 5%;
    margin-bottom: -7%;
    font-size: 30px;
    color: #000;
}


.placasServices {
    width: 100%;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-template-columns: repeat(2, auto);
    column-gap: 10%;

}


h2:nth-child(1) {
    padding: 30px 0 0 0;

}

h2:nth-child(2) {
    padding: 0 0 25px 0;

}

h4 {
    padding: 0 25px 8px 25px;
    letter-spacing: 0.5px;
    text-align: justify;
}


@media screen and (max-width: 840px) {
    .serviciosComp {
        height: auto;
    }

    .placasServices {
        height: auto;
        margin: 10% 0 5%;
    }
}

@media screen and (max-width: 790px) {
    .placasServices {
        grid-template-columns: repeat(1, auto);
        height: auto;

    }

    .serviciosComp {
        height: auto;
    }

    .serviciosTitulo {
        margin-top: 0%;
        font-size: 23px;
    }

    .placasServices {
        margin-top: 9%;
    }
}