.nosotrosContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;

}

.nosotrosTitulo {
    padding: 3% 3% 0;
    font-size: 30px;
    text-align: justify;
    color: #000;
}

.nosotrosRedaccion {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 20px;
    padding: 2% 5% 5%;
    text-align: justify;
    color: #000;
    line-height: 30px;
}

.glassContainer {
    width: 80%;
    height: auto;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 20px;
}

@media screen and (max-width: 890px) {
    .glassContainer {
        width: 90%;
    }

    .nosotrosRedaccion {
        padding: 2% 3% 3%;
    }

    .nosotrosContainer {
        height: auto;
        padding: 5%;
    }


}

@media screen and (max-width: 781px) {
    .glassContainer {
        width: 90%;
    }

    .nosotrosContainer {
        height: auto;
        margin: 2% 0 2%;
    }

}

@media screen and (max-width: 640px) {
    .nosotrosRedaccion {
        font-size: 19px;
        line-height: 25px;
    }
}

@media screen and (max-width: 515px) {
    .nosotrosRedaccion {
        font-size: 18.5px;
    }

    .nosotrosTitulo {
        font-size: 28px;
    }
}

@media screen and (max-width: 480px) {
    .nosotrosRedaccion {
        font-size: 17.5px;
        line-height: 23px;
    }

    .nosotrosTitulo {
        font-size: 24.5px;
    }
}

@media screen and (max-width: 425px) {
    .nosotrosRedaccion {
        font-size: 16px;
    }

    .nosotrosTitulo {
        font-size: 20px;
    }
}

@media screen and (max-width: 376px) {
    .nosotrosRedaccion {
        font-size: 15px;
        line-height: 18.5px;
    }

    .nosotrosTitulo {
        font-size: 19px;
    }
}